.heading{
    color: rgb(77, 77, 77);
    font-size: 14px;
    font-weight: 600;
}
.display-content{
color: rgb(104, 105, 105);
font-size: 12px;
font-weight: 600;
}

.fa-icon-pen{float: right;
}
.card-heading{
 float: center;
}
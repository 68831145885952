.formContainer-personal {
  z-index: 1;
  background: rgb(255, 255, 255);
  width: 350px;
  margin-top: 12%;
  margin-left: 25%;
  padding-bottom: 10px;
  text-align: center;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: 10px 0 45px 0px rgba(0, 0, 0, 0.2),
    0 5px 5px 7px rgba(0, 0, 0, 0.2);
  /* border: 1px solid black; */
}

.input-field-login {
  margin-top: 25px;
  width: 80%;
  border: 2px solid #e2e0e0;
  border-radius: 0 10px 0 10px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.formButtons-personal {
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  outline: 0;
  background-color: rgb(253, 253, 253);
  width: 40%;
  border: 1px solid #1de8b4;
  border-radius: 20px;
  padding: 5px;
  margin-top: 10%;
  margin-bottom: 8%;
  font-weight: 600;
  color: #1de8b4;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.formButtons-personal:hover {
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.inspire-text {
  color: #1de8b4;
}

.signtext {
  color: #1de8b4;
  font-size: 32px;
  font-weight: 500;
  padding-top: 15px;
  margin-left: 2%;
}

.user-text {
  padding-top: 40px;
  padding-left: 0%;
  color: #1de8b4;
}
h1 {
  font-size: 12px;
  color: black;
  padding-left: 37%;
  padding-top: 3%;
  cursor: pointer;
}
.buttom-text {
  font-size: 12px;
  font-weight: 500;
  color: #a8a8a8;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #e2e0e0;
  font-weight: 600;
}

.joinnow {
  font-size: 12px;
  font-weight: 800;
  color: #1de8b4;
}

.menuhr {
  border-bottom: 2px #cff6ed solid;
}

.each-fade .image-container img {
  width: 60% !important;
  height: 80% !important;
  margin-top: 7% !important;
  margin-left: 30% !important;
}
.image-container {
  max-width: 90% !important;
}

.img {
  width: 20%;
  margin-top: 10%;
  margin-left: 5%;
}

.logo-image {
  width: 25%;
  margin-left: 15%;
  margin: 2% 0% 2% 20%;
  /* margin-top: 1%; */
}
.p {
  font-size: 12px;
  padding-left: 5px;
  color: #1de8b4;
  margin-top: -1%;
}

.first-text {
  font-size: 14px;
  margin-left: 38%;
  color: #1de8b4;
  font-weight: 700;
}

.second-text {
  font-size: 14px;
  margin-left: 38%;
  font-weight: 700;
  color: #1de8b4;
}

.third-text {
  font-size: 14px;
  font-weight: 700;
  margin-left: 40%;
  color: #1de8b4;
}
.fourth-text {
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-left: 15%;
  color: #1de8b4;
}
.fourth-text-break {
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-left: 30%;
  color: #1de8b4;
}

h6 {
  font-size: 14px;
  margin-left: 60%;
  margin-top: -1px;
  color: #1de8b4;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

p {
  color: #66cc99;
  font-size: 14px;
  margin-left: 20%;
}
.aa {
  margin-left: 20%;
  color: #66cc99;
}
h5 {
  text-align: end;
  font-size: 14px;
  color: #1de8b4;
  margin-top: 4%;
}
h5 span {
  margin-right: 20px;
}

.signupbutton {
  color: #1de8b4 !important;
}
.left {
  max-width: 50% !important;
  text-align: center !important;
}

.react-slideshow-container + div.indicators > div {
  width: 7px;
  height: 7px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 50%;
  background: #ffffff !important;
  border: 1px solid #1de8b4 !important;
  cursor: pointer;
}

.react-slideshow-container + div.indicators > div.active {
  border: 4px solid #1de8b4 !important;
}
.react-slideshow-container + div.indicators {
  margin-left: 20% !important;
}

a {
  color: #1de8b4;
  text-decoration: none;
  background-color: transparent;
}
.error-message-login {
  font-size: 12px;
  color: red;
}
/* input { letter-spacing: 1.5px } */
.password-eye {
  margin-left: 70%;
  margin-top: -30px;
  color: #7d7d7d;
  cursor: pointer;
}

@media only screen and (max-width: 920px) {

.loggo{
  flex-basis : 0 !important;
}

  .left {
    max-width: 0% !important;
    display: none;
  }
  .right {
    max-width: 100% !important;
    text-align: center !important;
  }
  .formContainer-personal {
    z-index: 1;
    background: rgb(255, 255, 255);
    width: 80%;
    margin-top: 12%;
    margin-left: 10%;
    padding-bottom: 10px;
    text-align: center;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    box-shadow: 10px 0 45px 0px rgba(0, 0, 0, 0.2),
      0 5px 5px 7px rgba(0, 0, 0, 0.2);
    /* border: 1px solid black; */
  }
  .logo-image {
    width: 68% !important;
    /* margin-left: 25%; */
    margin: 10% 10% 9% 10% !important;
  }
  .signup-button-recover {
    float: right !important;
    margin-right: 0px !important;
    margin-top: 10% !important;
    background-color: rgb(253, 253, 253);
    border-radius: 20px !important;
    padding: 2px 13px !important;
    border: 1px solid #1de8b4;
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .signtext {
    color: #1de8b4;
    font-size: 20px;
    font-weight: 300;
    padding-top: 14px;
    margin-left: 2%;
  }
  h1 {
    font-size: 12px;
    color: black;
    padding-left: 37%;
    padding-top: 8%;
    cursor: pointer;
  }
}

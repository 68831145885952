.education_details{
    color: #4d4d4d;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
}
.education_details_degree{
    font-size:14px;
    color: #4d4d4d;
    padding-left: 10px;
    padding-bottom: 30px;
}
.education-container{
    padding-top: 5px;
    padding-bottom: 10px;
    margin-top: 10%;
    width: 100%;
    border-radius: 5px;
    padding-left: 10px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);

}
.edu_text{
    padding-top: 10px;
    color:#122A39;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
}
.fa-icon-pen-edu-profile{
padding-top: 8px;
   float: right;
    color:#1de8b4;
    padding-right: 20px!important;
    cursor: pointer;
    padding: 5px;
}
.education-text-field{
    width: 100%;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: 6px 10px;
}
.education-label{
color: #4d4d4d;
    font-size: 14px;
    margin-top: 10px;
}
.card-heading{
    color:#122A39;
    font-size: 14px;
    padding-left: 10px;
    font-weight: 600;
}
.more-education-container{
    padding: 2%;
    background-color: #ffffff;
    box-shadow: 10px 0 45px 0px rgba(0, 0, 0, 0.2), 0 5px 5px 7px rgba(0, 0, 0, 0.2);
    text-align: left!important;
  }
  .education-heading{
    color: rgb(93, 93, 93);
      font-size: 18px;
      font-weight: 800;
      text-align: left!important;
  }
  .split-container{
    padding: 1%;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 920px){
    
    .education-container {
        padding-top: 5px;
        padding-bottom: 10px;
        margin-top: 6%;
        width: 100%;
        border-radius: 5px;
        padding-left: 10px;
        box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 50%);
    }
    .target_education_more_btn{
        margin: 0px !important;
    }
    .edu_text {
        padding-top: 10px;
        color: #122A39;
        font-size: 16px;
        font-weight: 600;
        padding: 10px;
    }
    .col-md-2, .col-md-10, .col-md-4{
        width: 50%;
    }

  }
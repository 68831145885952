.vision-container2{
  width: 93%;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
  padding: 3%;
  margin-bottom: 2%;
  margin-left: 5px;
  font-size: 12px;
}
.vision{
  color: #122A39;
  font-size: 14px;
  font-weight: 600;
}
.fa-icon-pen{
  color: #1de8b4;
  float: right;
  font-size: 15px;
  cursor: pointer;
}
.profile-content-plans{
  color: #4c4c4c;
    font-size: 14px;
    padding-top: 10px;
}
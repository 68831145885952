.right-profile{
  max-width: 65%;
}
.laptop_view{
  display: block;
}
.mobile_view_section{
  display: none;
  
}
@media only screen and (max-width: 920px) {

  .laptop_view{
    display: none;
  }
  .mobile_view_section{
display: block;
  }

  .right-profile{
    max-width: 100% !important;
    padding-right: 0px;
      flex-basis: unset;
  }
  .hero345 {
    /* border: 1px solid black; */
    /* margin-top: -5px !important; */
    position: relative;
    top: -7%;
    width: 100%;
    float: right;
    height: auto !important;
    padding: 5px;
}
.dollowdrpdw {
  position: absolute;
  float: right;
  top: 30% !important;
  left: 82% !important;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: #1de8b4;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: #fbfbfb;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 30px;
  text-align: center;
}
.target_leader_header_section {
  position: relative;
  width: 95%;
  height: 418px !important;
  margin: 0 10% 5% auto 5%;
  /* border: 1px solid black; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 75%);
  margin-bottom: 30px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 20%;
}
.select-drop-down{
  top: 47% !important;
    left: 80% !important;
    z-index: 99;
}
}
.experiance-container{
  width: 93%;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
  padding: 3%;
  margin-bottom: 2%;
  margin-left: 5px;
  font-size: 12px;
}
.experiance-card-title{
  color: #122A39;
  font-size: 14px;
  font-weight: 600;
}
.split-cards-profile-containers {
  margin-top: 20px;
  padding: 1%;
}
.experiance-position{
font-size: 14px;
font-weight: 600;
}

.experiance-region{
  font-size: 14px;
font-weight: 600;
float: left;
}
.description-experiance-{
  font-size: 14px;
}
.experiance-date{
 
  margin-right: 100px;
}
.experience_dates_div{
  text-align: end;
}
.fa-icon-delete{
 color: #1de8b4;
  margin-right: 20px;
}
.edit-pen-delet-bin{
float: right;
}
.fa-icon-pen-right-profile{
color: #1de8b4;
font-size: 15px;
}

@media only screen and (max-width: 920px) {
.description-experiance- {
  font-size: 12px;
}
.experience_dates_div{
  text-align: unset;
}
.experiance-date {
  margin-right: 10%;
}
.experiance-region {
  font-size: 14px;
  font-weight: 600;
  float: left;
  width: 100%;
}
}
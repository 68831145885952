.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color:#112B3A;
  color: white;
  text-align: center;
  position: fixed;
  text-align: end;
  padding-right: 20px;
}
.footer-all-app{
  color: #ffffff;
}
.footer-labe-obaps{
  color: #ffffff;
}

@media only screen and (max-width:920px){
  .footer {
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #1bddad;
    text-align: center;
    position: fixed;
    text-align: end;
    padding-right: 3%;
  }

}
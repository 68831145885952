body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.nav-parent {
  width: 100%;
  border-bottom: 3px rgba(102, 204, 153, 0.2) solid;
  /* flex-direction: row; */
  /* justify-content: space-between; */
  position: fixed;
  z-index: 100;
  top: 0;
  height: 60px;
  /* margin-top: -101px; */
  background-color: #ffffff;
}

.nav-image {
  width: 5%;
  margin-left: 6%;
  margin-top: 7px;
  height: 46px !important;
  width: 50px !important;
}

/* .input-search {
      margin: 20px 15% auto 30% !important;
      height: 40px !important;
      width: 400px !important;
      border-radius: 5px !important;
      padding: 1% !important;
  } */

.nav-search-input-parent {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  height: 35px;
  box-sizing: border-box;
  outline: none;
  width: 30%;
  outline: none;
  border: 0.5px solid #1de8b4;
  border-radius: 5px;
  box-shadow: -1px 1px 5px 1px rgba(0, 0, 0, 0.5);
}
.icon-leaders {
  color: #1de8b4;
  font-size: 25px !important;
}
.log-out-card {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 4px 6px;
  width: 70%;
  text-align: end;
  margin-left: 30%;
}
.log-out-title {
  font-size: 12px;
  color: #4d4d4d;
  cursor: pointer;
}
.hr-logout {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}
.profile-nav {
  height: 32px !important;
  width: 32px !important;
  border-radius: 50%;
  background-color: rgb(224, 224, 224);
  border: 2px #1de8b4 solid;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
}
.nav-search-input-parent:hover {
  box-shadow: 0 1px 6px 5px rgba(128, 240, 161, 0.28);
  border: 1px rgb(202, 200, 200) solid;
}
.nav-search-input-parent-sub {
  /* width: 5%; */

  display: flex;
  padding: 5px 8px 0 16px;
  padding-left: 14px;
}

.leadersmenutext {
  font-size: 12px;
  font-weight: 300;
  color: #525252;
  padding-left: 10px;
}
.nav-search-input-parent-icon {
  display: flex;
  align-items: center;
  padding-right: 13px;
  margin-top: -5px;
  color: #ccc;
  /* border: 1px solid black; */
}

.nav-search-input-parent-input {
  display: flex;
  /* flex: 1; */
  /* margin-left: %; */
  flex-wrap: wrap;
  align-items: center;
  width: 85%;

  /* border: 1px solid black; */
}

.nav-search-input {
  background-color: transparent;
  border: none !important;
  box-shadow: none;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  outline: none;
  display: flex;
  flex: 100%;
  -webkit-tap-highlight-color: transparent;
  font: 16px arial, sans-serif;
  /* line-height: 34px; */
  height: 34px !important;
  /* border: 1px solid black; */
  width: 100%;
}

/* Search OUTPUT */
.nav-search-div {
  position: fixed;
  z-index: 9999;
  width: 30%;
  margin: 0 35% 0 35%;
  height: auto;
  background-color: #fff;
  padding: 0;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border-radius: 20px;
}

.nav-search-div-ul {
  width: 100%;
  list-style: none;
  padding: 0;
  /* border: 1px solid black; */
}

.nav-search-div-li {
  /* width: 100%; */
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav-search-div-li:hover {
  background-color: #cccccc2d;
}

.nav-search-div-image {
  width: 50px;
  height: 50px;
}
.Logoimage {
  margin-left: 5%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.nav-search-div-li p {
  margin: auto 0 auto 0;
  vertical-align: middle;
}

.nav-search-div-li i {
  /* border: 1px solid black; */
  border-radius: 50%;
  padding: 5px;
  background-color: #1de8b4;
  color: #fff;
}

.main-nav {
  color: #000000;
  float: right;
  list-style: none;
  margin-bottom: 0;
  font-size: 22px;
  margin-right: 20px;
  margin-top: 10px;
}

.main-nav li {
  display: inline-block;
  margin-left: 10px;
  /* border: 1px solid black; */
}

.main-nav li a {
  display: inline-block;
}

.main-nav li a i {
  font-size: 170%;
  margin-bottom: 5px;
  color: #1de8b4;
}

.main-nav li a p {
  font-size: 70%;
}

.main-nav li a:link,
.main-nav li a:visited {
  text-align: center;
  padding: 8px 0px;
  color: #000000;
  text-decoration: none;
  font-size: 90%;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-botton 0.2s;
  transition: border-botton 0.2s;
}

/* .main-nav li a:hover,
  .main-nav li a:active {
      border-bottom: 2px solid #e67e22;
  } */

.activeLink {
  border-bottom: 2px solid #000000;
}

.search-bar-input-field {
  width: 36%;
  margin-left: 18%;
  margin-left: 25%;
  padding: 4px 4px;
  border-radius: 5px;
  box-shadow: -1px 1px 5px 1px rgba(0, 0, 0, 0.5);
  margin-top: 12px;
  border: none;
  padding-left: 52px;
  position: absolute;
}
.search-bar-input-field:hover {
  box-shadow: -1px 1px 10px 1px #97ecd7;
}
.search-icon-feed {
  position: absolute;
  margin-left: 26%;
  margin-top: 17px;
  color: #9d9d9d;
}
.main-nav li a:link,
.main-nav li a:visited {
  display: table-caption !important;
}
.search-bar-img {
  height: 50px;
  width: 50px;
}
.search-bar-with-image {
  background-color: #ffffff;
  width: 40%;
  height: 550px;
  overflow: hidden;
}
.search-div {
  height: 395px;
  background-color: #ffffff;
  border: 1px solid #9b9b9b;
  width: 29%;
  margin-left: 35%;
  position: absolute;
  overflow-y: scroll;
  padding-left: 20px;
  z-index: 200;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.vertical-line {
  border-left: 1px solid #bbbbbb;
  height: 8px;
  padding-right: 10px;
}
.search-bar-image {
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 15px;
  height: 50px;
}
.noledaersfound-label {
  text-align: center;
  color: #4d4d4d;
}
.rightdrpdwn {
  text-align: "center";
  margin-left: 50px;
  cursor: "pointer";
}

@media only screen and (max-width: 920px) {
  .search-bar-input-field {
    width: 42%;
    margin-left: 18%;
    margin-left: 2%;
    padding: 4px 4px;
    border-radius: 5px;
    box-shadow: -1px 1px 5px 1px rgb(0 0 0 / 50%);
    margin-top: 4%;
    border: 1px solid;
    
    padding-left: 52px;
    position: absolute;
  }
  .rightdrpdwn {
    text-align: "center";
    margin-left: 12px;
    margin-right: 10px;
    cursor: "pointer";
  }
  .search-icon-feed {
    position: absolute;
    margin-left: 7%;
    margin-top: 21px;
    color: #9d9d9d;
  }
  .nav-image {
    width: 5%;
    margin-left: 6%;
    margin-top: 10px;
    height: 40px !important;
    width: 40px !important;
}
}

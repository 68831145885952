.logo {
  width: 10%;
  margin-left: 10%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.bottom-line {
  border-bottom: 3px rgba(120, 236, 178, 0.2) solid;
}
.Edit-leader-details-popup {
  box-shadow: 10px 0 45px 0px rgba(0, 0, 0, 0.2),
    0 5px 5px 7px rgba(0, 0, 0, 0.2);
  padding: 2%;
  background-color: #ffffff;
}
.edit-details-title {
  color: rgb(93, 93, 93);
  font-size: 18px;
  font-weight: 800;
}
.header {
  text-align: center;
  margin-top: 3%;
  font-size: 25px;
  color: #666668;
  font-weight: 400;
}

.header-text {
  text-align: center;
  margin-top: 0%;
  font-size: 20px;
  margin-bottom: 1%;
  color: #666668;
  font-weight: 300;
}
.formContainer-user {
  max-width: 70%;
  margin: auto;
  margin-left: 32%;
}

.select-button-field {
  color: #797979;
  padding-left: 1%;
  width: 25%;
  border: 2px solid #e2e0e0;
  border-radius: 0 10px 0 10px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  margin-top: 1%;
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.select-button-field-popup {
  color: #797979;
  padding-left: 1%;
  width: 25%;
  border: 2px solid #e2e0e0;
  font-size: 14px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  margin-top: 1%;
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.select-button-field-disabled {
  color: #afafaf;
  padding-left: 1%;
  width: 25%;
  border: 2px solid #e2e0e0;
  border-radius: 0 10px 0 10px;
  height: 40px;
  background-color: rgb(200, 200, 200);
  margin-top: 1%;
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.select-button-field-disabled-poup {
  color: #afafaf;
  padding-left: 1%;
  width: 25%;
  border: 2px solid #e2e0e0;
  height: 40px;
  font-size: 16px;
  background-color: rgb(200, 200, 200);
  margin-top: 1%;
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.select-button-field-state {
  color: #797979;
  padding-left: 1%;
  width: 25%;
  border-radius: 0 10px 0 10px;
  border: 2px solid #e2e0e0;
  border-radius: 0 10px 0 10px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  margin-top: 1%;
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.select-button-field-state-popup {
  color: #797979;
  padding-left: 1%;
  width: 25%;
  font-size: 16px;
  border: 2px solid #e2e0e0;
  height: 40px;
  background-color: rgb(255, 255, 255);
  margin-top: 1%;
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.required {
  color: #1de8b4;
}
.select-button-field-parliment {
  color: #797979;
  padding-left: 1%;
  margin-top: 1%;
  width: 25%;
  height: 30px;
  border-radius: 0 10px 0 10px;
  width: 25%;
  border: 2px solid #e2e0e0;
  border-radius: 0 10px 0 10px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.select-button-field-parliment-popup {
  color: #797979;
  padding-left: 1%;
  margin-top: 1%;
  width: 25%;
  height: 30px;
  font-size: 14px;
  border: 2px solid #e2e0e0;
  width: 25%;
  height: 40px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.label-field {
  width: 23%;
  margin-left: 5%;
  color: #858585;
}
.label-field-editpopup {
  color: #858585;
  font-size: 14px;
}
.edit-user-details-popup,
.edit-user-details-popup-country {
  width: 100%;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 4px 6px;
}
.edit-user-details-popup-country {
  background-color: #9c9c9c;
}
.formButtons-userdetails-edit {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  border-radius: 5px;
  outline: 0;
  background-color: #1de8b4;
  border: 1px solid #1de8b4;
  margin-left: 80%;
  margin-bottom: 2%;
  margin-top: 20px;
  width: 15%;
  padding: 4px 6px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}
.formButtons-userdetails-edit:hover {
  box-shadow: 2px 0 10px 0px rgba(0, 0, 0, 0.2),
    0 5px 10px 0px rgba(0, 0, 0, 0.2);
}
.hidable-ward {
  display: block;
}

.validation-error-messge-userdetails {
  color: red;
  font-size: 12px;
  margin-left: 28%;
}

/* border: 1px solid black; */

/* .input-field-user{
      width: 70%;
      height: 35px;
      border-color: rgb(238, 236, 236,236);
      border-width: 7px;
      margin-top: 20px;
      margin-bottom: 16px;
      margin-left: 30px;
  } */

.text-field-other {
  width: 25%;
  margin-top: 1%;
  height: 30px;
  border-radius: 0 10px 0 10px;
  width: 25%;
  border: 2px solid #e2e0e0;
  border-radius: 0 10px 0 10px;
  height: 40px;
  background-color: rgb(255, 255, 255);

  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.text-field-other-last {
  width: 25%;
  border-radius: 0 10px 0 10px;
  border: 1px solid #9e9d9d;
  margin-top: 2%;
}

.formButtons-userdetails {
  font-family: Lato, sans-serif;
  outline: 0;
  background-color: rgb(253, 253, 253);
  width: 8%;
  border: 2px solid #1de8b4;
  border-radius: 5px;
  padding: 5px;
  margin-top: 40px;
  margin-bottom: 25px;
  font-weight: 700;
  color: #1de8b4;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  margin-left: 8%;
}
.button-user {
  display: block;
  text-align: center;
}

select {
  color: -internal-light-dark-color(#1de8b4, white);
}

.formButtons-userdetails:hover {
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.select-button {
  padding-top: 0px;
  text-align: left;
}
#form-control-textfield {
  width: 80%;
  margin-top: 40px;
  margin-left: 30px;
}
.form-control-1 {
  display: block;
  width: 80%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-left: 30px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.submitexperiance {
  text-transform: uppercase;
  border-radius: 5px;
  outline: 0;
  background-color: #1de8b4;
  border: 1px solid #1de8b4;
  /* margin-left: 30%; */
  /* margin-right: 1%; */
  float: right;
  /* margin-top: 7px; */
  padding: 4px 6px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}
@media only screen and (max-width: 920px) {
  .logo {
    width: 25% !important;
    margin: 3% !important;
  }
  .header-text {
    text-align: center;
    margin-top: 0%;
    font-size: 12px;
    margin-bottom: 1%;
    color: #666668;
    font-weight: 300;
    padding: 3%;
  }
  .formContainer-user {
    max-width: 90%;
    margin: auto;
    /* margin-left: 20%; */
  }
  .label-field {
    width: 23%;
    margin-left: 5%;
    color: #858585;
  }
  .select-button-field-disabled {
    color: #afafaf;
    padding-left: 1%;
    width: 57%;
    border: 2px solid #e2e0e0;
    border-radius: 0 10px 0 10px;
    height: 40px;
    background-color: rgb(200, 200, 200);
    margin-top: 1%;
    box-shadow: 1px 0 1px 0px rgb(0 0 0 / 0%), 0 1px 2px 0px rgb(0 0 0 / 10%);
  }
  .rightbtnfld {
    width: 62%;
    margin: 2%;
  }
  .formButtons-userdetails {
    width: 25%;

    margin-top: 40px;
    margin-bottom: 25px;
  }
}

.section-profile {
    width: 100%;
    height: auto;
}
.left-profile{
    max-width: 35%;
    padding-left: 5%;
}
.left-folower{
    max-width:30%;
}
.right-follower{
    max-width: 70%;;
}
.right-profile{
    max-width: 65%;
}
.feedPageNav{
    width: 90%;
    height: auto;
    margin: 2% 5% auto 5%;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: center;
    padding: 20px;
}
.feedPageNav-1 {
    font-size: 120%;
    font-weight: bold;
    text-align: center;
    margin-left: 3%;
    padding: 2%;
    width: 30%;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
}
.feedPageNav-1:first-of-type{
    margin-left: 0;
}
.feedPageNav-1 p:last-of-type {
    font-size: 150%;
}
.pencil-vision-image{
 width: 3%;
 margin-left: 90%;
}
.plan-pencil-image{
    width: 3%;
    margin-left: 95%;
}
.section-profile-content{ 
    width: 90%;
    height: auto;
    margin: 2% 5% auto 5%;
}

/* --------------PROFILE CONTENT LEFT-------------- */

.profile-content-left{
    float: left;
    width: 100%;
    height: 100%;
    margin: 0;
    

}

.profile-content-info {
    width: 30%;
    /* height: 150px; */
    margin-top: 15%;
    /* border: 1px solid black; */
    border-radius: 10px;
    
    box-shadow: -1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 3%;
    text-align: center;
}
.popup-content{
    background: transparent  !important;
    border: 0px solid rgb(187, 187, 187) !important;
    
}
.pencil-image{
    margin-left: 85%;
    margin-top: 10%;
    width: 15px;
    
}
.plus-btn{
    float: right;
    margin-top: -2%;
    font-size: 25px;
    font-weight: 500;
    color: #22485B;
    margin-right: 10px;
    cursor: pointer;
    padding: 1%;
}

.dairies-margin{
    margin-bottom: 5%;
    float: left;
}
.pencil-edc{
    width: 15%;
    margin-left: 90%;
}
.profile-content-info-del{
    width: 85%;
    /* height: 150px; */
    margin-top: 12%;
    /* border: 1px solid black; */
    border-radius: 5px;
    margin-left: 17%;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 2%;
    text-align: center;
    margin-bottom: 5%;
    font-size: 12px;
}
.profile-content-info-del1{
    width: 100%;
    /* height: 150px; */
    /* border: 1px solid black; */
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 2%;
    margin-bottom: 5%;
    font-size: 12px;
    margin-top: 2%;
}
.profile-content-info-del2{
    width: 100%;
    overflow: hidden;
    margin-top: 5%;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 2%;
    font-size: 12px;
    margin-bottom: 5%;
    
}
.dairy_pics{
    text-align: center;
    margin-top: 0%;
}
.profile-content-info-del3{
    width: 100%;
    /* height: 150px; */
    margin-top: 10%;
    /* border: 1px solid black; */
    border-radius: 5px;
    margin-left: 17%;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 2%;
    margin-bottom: 5%;
    font-size: 12px;
}
.profile-content-info-del4{
    width:80%;
    /* height: 150px; */
    margin-top: 10%;
    /* border: 1px solid black; */
    border-radius: 5px;
    margin-left: 17%;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 2%;
    text-align: center;
    margin-bottom: 5%;
    font-size: 12px;
}

.vision{
    color: #1de8b4;
    font-size: 14px;
    font-weight: 500;
}
.vision-text{
    color: rgb(102, 102, 102);
    font-size: 14px;
}
/* .popup-content{
    width:10%
} */

.fa-icon-pen{
    color: #1de8b4;
    float: right;
    font-size: 15px;
    cursor: pointer;
    padding-right: 10px;
}
.Icon-box1{
    width: 20%;
    height: 60px;
    margin-left: 0%;
    background-color: #1de8b4;
    
}
.Icon-box2{
    width: 20%;
    height: 60px;
    margin-left: 8%;
    background-color: #1de8b4;
    
}
.Icon-box3{
    width: 20%;
    height: 60px;
    margin-left: 8%;
    background-color: #1de8b4;
    
}
.photo-major4{
    width: 8%;
    margin-left: 25%;
    height: 70px;
    background-color: rgb(36, 35, 35);
}
.photo-second{
    width: 30%;
    height: 70px;
    background-color: grey;
    
}
.modiji-image{
    margin-top: 1%;
}
.vision-container{
    width: 93%;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 3%;
    margin-bottom: 2%;
    margin-left: 5px;
    font-size: 12px;
}
.vision-container-follower{
    width: 89%;
    height: 30rem;
    /* height: 150px; */
    margin-top: -30%;
    /* border: 1px solid black; */
    border-radius: 10px;
    margin-left: 50%;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 2%;
    
    margin-bottom: 5%;
    font-size: 12px;
}

.vision-container4{
    width: 93%;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 1%;
    margin-bottom: 5%;
    margin-left: 5px;
    font-size: 12px;
}
.vision-container5{
    width: 93%;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 1%;
    margin-bottom: 5%;
    margin-left: 5px;
    font-size: 12px;
}
.vision-container6{
    width: 93%;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 1%;
    margin-bottom: 5%;
    margin-left: 5px;
    font-size: 12px;
}
.social-text{
    color: #1de8b4;
    font-size: 20px;
    font-weight: 600;
    
}
.plus-social{
    float: right;
}
.content-left3{
    margin-left: 25%;
    margin-top: -8%;
}
.content-left4{
    margin-left: 5%;
    
    
}
.photo-major{
    margin-left: 25%;
    background-color: rgb(53, 51, 51);
    height: 15%;
    width: 10%;
}
.photo-major2{
    margin-left: 0%;
    background-color: grey;
    height: 15%;
    width: 30%;

}
.vision-container3{
    width: 85%;
    /* height: 150px; */
    margin-top: 4%;
    /* border: 1px solid black; */
    border-radius: 10px;
    margin-left: 8%;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 2%;  
    margin-bottom: 5%;
}
.photo-button{
    background-color: rgb(36, 35, 35);
    margin-top: 3%;
    margin-left: 1%;
    width: 11%;
    height: 30%;
}
.plus{
    width: 20%;
    
}
.photo-button2{
    background-color: grey;
    margin-top: 3%;
    margin-left: 0%;
    width: 30%;
    height: 30%;
}
.profile-content{
    width: 100%;
    /* height: 150px; */
    margin-top: 50px;
    /* border: 1px solid black; */
    border-radius: 10px;
    box-shadow: -1px 1px 5px 1px rgba(0,0,0,0.50);
    padding: 3%;
    text-align: center;
}
.profile-content-info:first-of-type {
    margin-top: 0;
}
.profile-content-info-footer{
    text-align: center;
    font-weight: bold;
    color: #1de8b4;
    
}
.logo-text1{
    width: 8%;
    height: 20%;
    text-align: center;
}
.logo-text2{
    width: 8%;
    height: 50px;
    text-align: center;
}
.content-left{
    margin-left: 5%;
}
.content-left1{
    margin-left: 15%;
    margin-top: -3%;
}
.pencil-image{
    width: 2%;
    margin-top: -8%;
    margin-left: 90%;
}
.profile-content-info-body p:first-of-type{
    font-weight: bold;
}
.profile-content-info-body p:last-of-type{
    font-size: 80%;
}

.profile-content-info p {
    margin-bottom: 7px;
}

.profile-content-info-body table{
    text-align: left;
    width: 90%;
    font-size: 90%;
    margin-left: 5%;   
}

.profile-content-info-body td {
    width: 50%;
    padding: 2%;
    
}

.profile-content-info-body td:first-of-type{
    font-weight: bold;
}

.demo-photo-flex{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.demo-photo{
    flex-basis: 28%;
    height: 126px;
    /* width: 50px; */
    margin: 10px 10px;
    /* border-radius: 10px; */
    font-size: 14px;
    transition: 300ms ease;
    background-color: #1de8b4;
}


.flex-icons{
    padding: 5%;
    font-size: 150%;
    color: #1de8b4;
    
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-evenly;
}

/* ------------------PROFILE CONTENT RIGHT------------------ */
.profile-content-right {
    width: 60%;
    height: auto;
    margin-left: 40%;
    /* border: 1px solid black; */
    
}

.profile-content{
    text-align: left; 
}

.profile-content .profile-content-info-header{
    font-size: 150%;
    margin-bottom: 20px;
}


.profile-content .profile-content-info-footer {
    text-align: center;
}

.profile-content-info-body p:first-of-type{
    font-weight: bold;
}
.profile-content-info-body p:last-of-type{
    font-size: 80%;
}

.profile-content-info-body-sub, .accomplishment{
    
    width: 100%;
    height: 150px;
    margin-top: 50px;
}

.profile-content-info-body-sub:first-of-type,
.accomplishment:first-of-type{
    margin-top: 0;
}

.body-sub-1{
    width: 100%;
    height: 60px;
    
    display: flex;
    flex-direction: row;
}
.dairies-image{
    width:5%;
    margin-left: 85%;
}

.body-sub-1-image {
    margin-left: 10px;
    margin-top: 5px;
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border: 1px solid black;
}

.body-sub-1-title{
    margin-left: 10px;
    margin-top: 5px;

}

.body-sub-1-date{
    margin-left: 40%;
    margin-top: 10px;
    color: #7590A7;
}

.body-sub-2{
    color: #7590A7;
    
    width: 100%;
    height: 90px;
}

.body-sub-2 p{
    width: 100%;
    margin-left: 70px;

}

.accomplishment .body-sub-1 {
    float: left;
    width: 60%;
    height: 100%;
    padding: 1%;
    
}

.accomplishment .body-sub-1 p:last-of-type{
    margin-top: 5px;
}

.award-details{
    width: 100%;
    font-size: 80%;
    margin-top: 5px;
}
.award-details span {
    margin-left: 40%;
    font-size: 75%;
}

.accomplishment .body-sub-2 {
    float: left;
    width: 40%;
    height: 100%;
    padding: 1%;
    
}

.body-sub-2-image{
    width: 100%;
    height: 100%;
    background-color: #22485B;
}



.profile-content-info p {
    margin-bottom: 7px;
}

.profile-content-info-body table{
    text-align: left;
    width: 90%;
    font-size: 90%;
    margin-left: 5%;   
}
/* .first-table{
    font-size: 14px;
    font-style: normal;
    color: black; */


.profile-content-info-body td {
    width: 50%;
    padding: 2%;
    
}

.profile-content-info-body td:first-of-type{
    font-weight: bold;
}


@media screen and (max-width:1380px){
    .leader-feed-alignment-div{
        margin-left: 6%;
        margin-right: 6%;
}
}
@media screen and (min-width:1381px){
    .leader-feed-alignment-div{
      margin-left: 10%;
      margin-right: 10%;
    }
}
@media screen and (min-width: 1750px){
.leader-feed-alignment-div {
    margin-left: 15%;
    margin-right: 15%;
}
}
/* @media screen and (max-width: 2000px) and (min-width: 1621px) {
    .leader-feed-alignment-div{
      margin-left: 15%;
      margin-right: 15%;
    }
} */

@media screen and (max-width: 920px){

    .profile-content-info-del1{
        margin-top: 0%;
    }

}



.leftbanner {
  width: 100%;
  height: 100%;
}

.formContainer-verify {
  z-index: 1;
  background: rgb(255, 255, 255);
  width: 330px;
  padding-top: 10px;
  margin-left: 250px;
  margin-top: 160px;
  padding-bottom: 20px;
  text-align: center;

  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 10px 0 45px 0px rgba(0, 0, 0, 0.2),
    0 5px 5px 7px rgba(0, 0, 0, 0.2);
  /* border: 1px solid black; */
}

.formButtons-verify {
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  outline: 0;
  background-color: rgb(253, 253, 253);
  width: 40%;
  border: 1px solid #1de8b4;
  border-radius: 20px;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 2%;
  font-weight: 600;
  color: #1de8b4;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.resend-otp {
  padding-top: 40px;
  color: #1de8b4;
  font-size: 14px;
  cursor: pointer;
}

.formButtons-verify:hover {
  box-shadow: 0px 1px 10px 1px rgba(52, 234, 188),
    0px 2px 2px 0px rgba(52, 234, 188), 0px 1px 5px 0px rgba(52, 234, 188);
}

.inspire-text {
  color: #1de8b4;
  padding-bottom: 12%;
  padding-top: 5%;
  font-size: 28px;
  font-weight: 600;
}

.otptext-otp {
  color: #1de8b4;
  font-size: 22px;
  font-weight: 500;
  padding-top: 200px;
  padding-bottom: 100px;
}

.user-text-verify {
  color: #797777;
  font-size: 12px;
  font-weight: 500;
}

.input-field-container-otp {
  padding-top: 10px;
  padding-left: 80px;
}

.paratext {
  font-size: 8px;
  font-weight: 600;
  color: rgb(151, 150, 150);
}

.buttom-paragraph {
  line-height: 80%;
}
.buttom-verify-text {
  margin-top: 3%;
}

#divInner {
  left: 0;
  position: sticky;
}

#divOuter {
  width: 190px;
  overflow: hidden;
}

.otptext {
  color: #1de8b4;
  font-size: 22px;
  font-weight: 500;
  padding-top: 20rem;
  margin-bottom: 0%;
}
#partitioned {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  height: 30px;
  background-image: linear-gradient(
    to left,
    rgb(185, 234, 219) 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 30px;
  background-color: rgb(255, 255, 255);
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  min-width: 220px;
}

.input-field-otp {
  /* margin-top: 25px;
width: 80%;
border:2px solid #cccbcb;
border-radius: 0 20px 0 20px;
height: 40px;
background-color: rgb(255, 255, 255); */
  width: 80%;
  border: 2px solid #e2e0e0;
  border-radius: 0 10px 0 10px;
  height: 30px;
  background-color: rgb(255, 255, 255);
  margin-top: 5px;
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.bottom-text {
  margin-top: 2%;
}
.validation-error-messge {
  color: red;
  font-size: 12px;
}
.user-text-login {
  font-size: 12px;
  font-weight: 600;
  color: #1de8b4;
  cursor: pointer;
}
@media only screen and (max-width: 920px) {
  .formContainer-verify {
    z-index: 1;
    background: rgb(255, 255, 255);
    width: 330px;
    padding-top: 10px;
    margin-left: 2%;
    margin-top: 25%;
    padding-bottom: 20px;
    text-align: center;

    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 10px 0 45px 0px rgba(0, 0, 0, 0.2),
      0 5px 5px 7px rgba(0, 0, 0, 0.2);
    /* border: 1px solid black; */
  }
  .leftbanner{
    flex-basis: unset !important;
    display: none;
    width: 0%;
  }
  .home-right{
    flex-basis: unset !important;
  }
}

.formContainer-others {
  z-index: 1;
  background: #ffffff;
  width: 380px;

  margin-left: 22.3%;
  margin-top: 10.7%;
  text-align: center;
  padding-bottom: 20px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: 10px 0 45px 0px rgba(0, 0, 0, 0.2),
    0 5px 5px 7px rgba(0, 0, 0, 0.2);
  /* border: 1px solid black; */
}
.hint-label {
  font-size: 12px;
  padding-left: 10px;
  color: #4d4d4d;
}
.menues {
  float: right;
  font-size: 14px;
  color: #1de8b4;
  padding-right: 2%;
  margin-top: 2%;
}

.forgot-left-image {
  width: 60%;
  margin-left: 10%;
  margin-top: 8%;
}
.recover-logo {
  width: 10%;
  margin-left: 5%;
  margin-top: 2%;
}
.header-recover {
  background-color: aquamarine;
}
.input-field-user {
  width: 80%;
  height: 35px;
  border-color: rgb(238, 236, 236, 236);
  border-width: 0.5px;
  margin-top: 20px;
  margin-bottom: 16px;
}

.forgot-text {
  color: #1de8b4;
  font-size: 20px;
  font-weight: 700;
  padding-top: 10%;
  margin-left: 2%;
  padding-bottom: 2%;
}
.formButtons {
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  outline: 0;
  background-color: rgb(253, 253, 253);
  border: 1px solid #1de8b4;
  border-radius: 20px;
  padding: 6px 20px;
  margin-top: 40px;
  margin-bottom: 25px;
  font-weight: 600;
  color: #1de8b4;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.formButtons:hover {
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.select-button {
  padding-top: 6px;
  text-align: left;
}
.pass {
  font-size: 14px;
  color: rgb(133, 132, 132);
  margin-left: 1%;
}
.par {
  font-size: 12px;
  margin-left: 1%;
  color: rgb(133, 131, 131);
}

.joinnow-recover {
  color: #1de8b4;
  font-size: 12px;
  font-weight: 800;
}
.logo-image {
  width: 25%;
  margin-left: 15%;
  margin: 2% 0% 2% 20%;
}

.input-field-recover {
  margin-top: 25px;
  width: 80%;
  border: 2px solid #e2e0e0;
  border-radius: 0 10px 0 10px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.card-buttom-text {
  text-align: end;
}
.validation-error-messge {
  color: red;
  font-size: 12px;
}
.error-message-recover {
  color: red;
  font-size: 12px;
}
.signup-button-recover {
  float: right;
  margin-right: 100px;
  margin-top: 3%;
  background-color: rgb(253, 253, 253);
  border-radius: 20px;
  padding: 6px 20px;
  border: 1px solid #1de8b4;
  font-size: 16px;
  font-weight: 600;
}
.signup-button-recover:hover {
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.menuhr {
  border-bottom: 2px #cff6ed solid;
}

@media only screen and (max-width: 920px) {
  .left {
    max-width: 0% !important;
    display: none;
  }
  .right {
    max-width: 100% !important;
    text-align: center !important;
  }
  .formContainer-others {
    z-index: 1;
    background: #ffffff;
    width: 90% !important;

    margin-left: 5% !important;
    margin-top: 10.7% !important;
    text-align: center !important;
    padding-bottom: 20px !important;
    border-top-left-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
    box-shadow: 10px 0 45px 0px rgba(0, 0, 0, 0.2),
      0 5px 5px 7px rgba(0, 0, 0, 0.2);
    /* border: 1px solid black; */
  }
  .formButtons {
    font-size: 12px !important;
  }
}

.logo {
  width: 10%;
  margin-left: 10%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.bottom-line {
  border-bottom: 3px rgba(120, 236, 178, 0.2) solid;
}

.upload-image-button {
  border: 2px #1de8b4 solid;
  color: #1de8b4;
  border-radius: 0 5px 0 5px;
  margin-left: 8%;
  background-color: white;
  width: 78%;
  height: 30%;
}
.text-field-other {
  color: #696868;
  padding-left: 1%;
  width: 25%;
  border: 2px solid #e2e0e0;
  border-radius: 0 10px 0 10px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  margin-top: 25px;
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  margin-top: 1%;
  font-size: 25px;
  color: #666668;
  font-weight: 400;
}

.profile {
  margin-top: 0%;
}

.log {
  width: 10%;
  height: 10%;
  margin-top: 1%;
  margin-left: 5%;
}

.circle {
  margin-left: 8%;
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgb(250, 244, 244);
  border-radius: 50%;
  border: 2px rgb(212, 210, 210) solid;
}

.boxer {
  width: 20%;
}
.butt {
  font-family: Lato, sans-serif;
  outline: 0;
  background-color: rgb(253, 253, 253);
  width: 8%;
  border: 2px solid #1de8b4;
  border-radius: 5px;
  padding: 5px;
  margin-top: 40px;
  margin-bottom: 25px;
  font-weight: 700;
  color: #1de8b4;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  margin-left: 27%;
}
.butt:hover {
  box-shadow: 1px 0 10px 0px rgba(0, 0, 0, 1), 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.button-enable {
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
}
.button-enable1 {
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
  color: #1de8b4;
  border-color: white;
}
.uploadbutton {
  font-family: Lato, sans-serif;
  outline: 0;
  margin-top: 10%;
  margin-left: 5%;
  background-color: rgb(253, 253, 253) !important;
  border-radius: 20px !important;
  padding: 4px;

  font-weight: 700;
  color: #1de8b4;
  font-size: 10px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.profile-label {
  margin-top: 15%;
  margin-left: 20%;
  color: #858585;
}
.profile-row {
  margin-top: 5%;
  margin-left: 4%;
  margin-bottom: 4%;
}
.profile-left {
  max-width: 20%;
}
.profile-center {
  float: left;
  margin-left: 0%;
  max-width: 20%;
}
.profile-right {
  max-width: 15%;
}

.img {
  width: 20%;
  height: 15%;
}
.danger {
  width: 5%;
  margin-left: 5%;
}

.disabletext {
  color: #858585;
  margin-bottom: 10px;
  border: 1px #a19f9f solid;
  padding: 0 2px 0 2px;
  border-radius: 2px;
  font-size: 12px;
}

.fa-icon-pen-profiledetails {
  /* left: 55%; */
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #707070;
  border-radius: 50%;
  color: #1de8b4;
  font-size: 14px;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  text-align: center;
  opacity: 0.5;
  height: 40px;
  width: 40px;
  margin-top: 5%;
  margin-left: 15%;
}
.profileimage-details {
  position: absolute;
  /* transform: translate(-50%, -50%); */

  background-color: #707070;
  border-radius: 50%;
  color: #1de8b4;
  /* font-size: 14px; */
  /* padding: 8px 12px; */
  border: none;
  cursor: pointer;
  /* text-align: center; */
  opacity: 0;
  height: 40px;
  width: 40px;
  opacity: 0;
  margin-top: -8px;
  /* margin-left: -31%; */
  margin-left: -12px;
  cursor: pointer;
}
.fa-icon-exclamation1 {
  color: #1de8b4;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-left: 28%;
}
.error-message-profile {
  color: red;
  font-size: 12px;
  margin-left: 5%;
  margin-top: 40px;
}
.progressbar {
  width: 30%;
  margin-top: 20px;
  padding-left: 10;
}
.btn-primary {
  background-color: #1de8b4 !important;
  border-color: #1de8b4 !important;
}
.enableempty {
  padding: 6px 10px;
}
.strenghth-bar {
  width: 17%;
  position: absolute;
  margin-left: 19%;
  margin-top: 5px;
}
p {
  margin: -5px 0px 0px !important;
}
.password-eye-profile {
  margin-left: 50%;
  margin-top: -30px;
  color: #7d7d7d;
  cursor: pointer;
}
@media only screen and (min-width: 1680px) {
  .profileimage-details {
    position: absolute;
    /* transform: translate(-50%, -50%); */
    background-color: #707070;
    border-radius: 50%;
    color: #1de8b4;
    /* font-size: 14px; */
    /* padding: 8px 12px; */
    border: none;
    cursor: pointer;
    /* text-align: center; */
    opacity: 0;
    height: 40px;
    width: 40px;
    opacity: 0;
    margin-top: -6px;
    /* margin-left: -31%; */
    margin-left: -16px;
    cursor: pointer;
  }
  .fa-icon-pen-profiledetails {
    /* left: 121%; */
    position: absolute;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #707070;
    border-radius: 50%;
    color: #1de8b4;
    font-size: 14px;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    text-align: center;
    opacity: 0.5;
    height: 40px;
    width: 40px;
    margin-top: 30%;
    margin-left: 93%;
  }
  .circle {
    margin-left: 60%;
    position: absolute;
    width: 100px;
    height: 100px;
    background: rgb(250, 244, 244);
    border-radius: 50%;
    border: 2px rgb(212, 210, 210) solid;
  }
}
@media only screen and (min-width: 1380px) {
  .profileimage-details {
    position: absolute;
    /* transform: translate(-50%, -50%); */
    background-color: #707070;
    border-radius: 50%;
    color: #1de8b4;
    /* font-size: 14px; */
    /* padding: 8px 12px; */
    border: none;
    cursor: pointer;
    /* text-align: center; */
    opacity: 0;
    height: 40px;
    width: 40px;
    opacity: 0;
    margin-top: -6px;
    /* margin-left: -31%; */
    margin-left: -16px;
    cursor: pointer;
  }
  .fa-icon-pen-profiledetails {
    /* left: 121%; */
    position: absolute;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #707070;
    border-radius: 50%;
    color: #1de8b4;
    font-size: 14px;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    text-align: center;
    opacity: 0.5;
    height: 40px;
    width: 40px;
    margin-top: 35%;
    margin-left: 104%;
  }
  .circle {
    margin-left: 60%;
    position: absolute;
    width: 100px;
    height: 100px;
    background: rgb(250, 244, 244);
    border-radius: 50%;
    border: 2px rgb(212, 210, 210) solid;
  }
}
@media only screen and (max-width: 1379px) {
  .profileimage-details {
    position: absolute;
    /* transform: translate(-50%, -50%); */
    background-color: #707070;
    border-radius: 50%;
    color: #1de8b4;
    /* font-size: 14px; */
    /* padding: 8px 12px; */
    border: none;
    cursor: pointer;
    /* text-align: center; */
    opacity: 0;
    height: 40px;
    width: 40px;
    margin-top: -6px;
    /* margin-left: -31%; */
    margin-left: -16px;
    cursor: pointer;
  }
  .fa-icon-pen-profiledetails {
    /* left: 121%; */
    position: absolute;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #707070;
    border-radius: 50%;
    color: #1de8b4;
    font-size: 14px;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    text-align: center;
    opacity: 0.5;
    height: 40px;
    width: 40px;
    margin-top: 40%;
    margin-left: 104%;
  }
  .circle {
    margin-left: 60%;
    position: absolute;
    width: 100px;
    height: 100px;
    background: rgb(250, 244, 244);
    border-radius: 50%;
    border: 2px rgb(212, 210, 210) solid;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: -12px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1de8b4;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1de8b4;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.headerrespons {
  /* position: relative;
top: 18px;
margin-top: 0px;
left: 29px; */
  margin-left: 7%;
}

@media only screen and (max-width: 920px) {
  .headerrespons {
    width: 100%;
    margin-left: 0%;
  }

  .fa-icon-pen-profiledetails {
    margin-top: 85%;
    margin-left: 158%;
    height: 30px;
    width: 30px;
    font-size: 12px;
    padding: 6px 9px;
  }

  .password-eye-profile {
    margin-left: 90%;
    margin-top: -30px;
    color: #7d7d7d;
    cursor: pointer;
  }

  .logo {
    width: 25% !important;
    margin: 3% !important;
  }
  .header {
    text-align: center;
    margin-top: 1%;
    font-size: 20px;
    color: #666668;
    font-weight: 400;
  }
  .label-field {
    width: 29%;
    margin-left: 5%;
    color: #858585;
  }
  .text-field-other {
    color: #696868;
    padding-left: 1%;
    width: 62%;
    border: 2px solid #e2e0e0;
    border-radius: 0 10px 0 10px;
    height: 34px;
    background-color: rgb(255, 255, 255);
    margin-top: 25px;
    box-shadow: 1px 0 1px 0px rgb(0 0 0 / 0%), 0 1px 2px 0px rgb(0 0 0 / 10%);
  }
  .circle {
    margin-left: 60%;
    position: absolute;
    width: 75px;
    height: 75px;
    background: rgb(250, 244, 244);
    border-radius: 50%;
    border: 2px rgb(212, 210, 210) solid;
  }
  .butt {
    font-family: Lato, sans-serif;
    outline: 0;
    background-color: rgb(253, 253, 253);
    width: 23%;
    border: 2px solid #1de8b4;
    border-radius: 5px;
    padding: 5px;
    margin-top: 40px;
    margin-bottom: 25px;
    font-weight: 700;
    color: #1de8b4;
    font-size: 14px;
    transition: all 0.3 ease;
    cursor: pointer;
    margin-left: 40%;
    /* text-align: center; */
  }
  .canvascoverpic {
    margin-top: 0%;
  }
  .headersectiontargetleader {
    margin-top: 19% !important;
    height: 370px !important;
  } 
}

.loading{
    text-align: center;
    color: darkblue;
    font-size: 40px;
}

.left-banner{
    height: 100%;
    width: 100%;
    border-radius: 100px 0 100px 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.formContainer {
    z-index: 1;
    background: rgb(255, 255, 255);
    width: 300px;
    padding-top: 10px;
    margin-left: 25%;
    margin-top: 20%;
    height: 350px;
    text-align: center;
   
    border-top-left-radius:30px ;
    border-bottom-right-radius: 30px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 7px rgba(0, 0, 0, 0.2);
    /* border: 1px solid black; */
  }
.social-link-container{
padding: 10px;
    padding-bottom: 10px;
    margin-top: 5%;
    width: 100%;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
}
.fa-icon-pen-social{
float: right;
color: #1de8b4;
cursor: pointer;
margin-top: -41px;
}

.Social-links{
  color: #1de8b4;
  font-size: 30px;
  margin-right: 0px;
  margin-left: 20px;
  margin-top: 20px;
}


.globe{
  padding-right: 10px;
 
}

.add_socisl_links-button{
  margin-top: 20px;
  font-size: 14px;
  font-weight:600;
  margin-bottom: 20px;
  padding: 2px 10px;
  background-color: #1de8b4;
  border-radius: 5px;
  border: 1px solid #1de8b4;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-self: center;
}
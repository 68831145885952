@media only screen and (max-width: 920px) {

  
    .search-div {
      height: 395px;
      background-color: #ffffff;
      border: 1px solid #9b9b9b;
      width: 90%;
      margin-left: 5%;
      position: absolute;
      overflow-y: scroll;
      padding-left: 20px;
      z-index: 200;
      box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 50%);
      cursor: pointer;
  }
  .noledaersfound-label {
    text-align: center;
    color: #4d4d4d;
    padding: 16px;
}
  }

.error404{
    align-self: center;
    align-items: center;
    text-align: center;
    font-size: 140px;
    font-weight: 1000;
    color: rgb(102, 99, 99);
}
.errorpage{
    text-align: center;
    margin-top: 15%;
    margin-left: 0;
}
.page{
    color: #1de8b4;
    font-size: 50px;
}
.loginagain{
    padding: 10px;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 600;
    width: 10%;
    color: #ffffff;
    background-color: #1de8b4;
    border: 2px solid #1de8b4;
    border-radius: 5px;
}
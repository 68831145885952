.mydairies-image-left{
  margin-right: 3px;
  margin-top: 1%;
  cursor: pointer;
  height: 100px;
    width:32%;
  margin-left: 3px;
}
.mydairies-image-center{
 margin-right: 3px;
 margin-top: 1%;
 height: 100px;
    width:32%;
 cursor: pointer;
}
.mydairies-image-right{
  margin-top: 1%;
  cursor: pointer;
  height: 100px;
    width:32%;
}
.fa-icon-square-plus{
  font-size: 80px;
  margin-top: 1%;
  color: #1de8b4;
  cursor: pointer;
}
.plus-button-profile-left{
  float: right;
  margin-top: -2%;
  font-size: 25px;
  font-weight: 500;
  color: #22485B;
  margin-right: 10px;
  cursor: pointer;
}
@media screen and (max-width:1381px){
  .mydairies-image-left{
    margin-right: 3px;
    margin-top: 1%;
    cursor: pointer;
    margin-left: 3px;
    height: 100px;
    width:30%;
  }
  .mydairies-image-center{
   margin-right: 3px;
   margin-top: 1%;
   cursor: pointer;
   height: 100px;
    width:30%;
  }
  .mydairies-image-right{
    margin-top: 1%;
    cursor: pointer;
    height: 100px;
    width:30%;
  }
}
.major-accoumplishment-card{
  width: 93%;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.50);
  padding: 3%;
  margin-bottom: 2%;
  margin-left: 5px;
  font-size: 12px;
  overflow: hidden;
}
.major-accomplishment-heading{
  color: #122A39;
  font-size: 14px;
  font-weight: 600;
}
.split-cards-profile-containers {
  margin-top: 0px;
  padding: 1%;
}
.plus-btn-accomplishment{
  float: right;
  margin-top: -2%;
  font-size: 25px;
  font-weight: 500;
  color: #22485B;
  margin-right: 10px;
  cursor: pointer;
  padding-top: 10px;
}
.leader-title{
  font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.border-bottom-accopomplishment{
  border-bottom: 1px solid rgb(194, 194, 194);
}
.label-accomplishment{
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 600;
}
.fa-icon-pen-accomplishment{
  color: #1de8b4;
margin-right: 10px;
cursor: pointer;
font-size: 15px;
}
.edit-pen-bin{
  float: right;
}
.view-document{
  padding: 2px 4px;
    background-color: #1de8b4;
    border: 2px solid #1de8b4;
    border-radius: 5px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 10px;
}
.label-awrdedon{
  
}
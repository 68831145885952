.hero-text-box {
    position: relative;
    width: 95%;
    height: 450px !important;
    margin: 0 10% 5% auto 5%;
    /* border: 1px solid black; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    margin-bottom: 30px;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 6%;
}
.hero-text-box-follower {
    position: relative;
    width: 95%;
    height: 550px !important;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}


.fa-icon-pen-profile , .profileimage{
    position: absolute;
    top: 80%;
    left: 85%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #707070;
    border-radius: 50%;
    color: #1de8b4;
    font-size: 14px;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    text-align: center;
    opacity: 0.5;
    height: 45px;
    width: 45px;
    padding-bottom: 10px;
}

.profileimage{
    opacity: 0;
    margin-left: -20px;
    margin-top: -14px;
    cursor: pointer;
}
.coverphoto,.coverpicadjuster,.picsubmit{
    position: absolute;
    float: right;
  top:60%;
  left: 90%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: #1de8b4;
  backdrop-filter: blur(1px);
  background-color: #fbfbfb;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #1de8b4;
  cursor: pointer;
  border-radius: 30px;
  text-align: center;
}
.coverphoto{
    width: 110px;
    height: 30px;
    margin-top: -86px;
    opacity: 0;
}
.picsubmit{
    width: 70px;
    height: 30px;
    text-align: center;
    margin-top: -85px;
}
.picsubmit:hover{
    background-color: rgb(27, 27, 27);
}
.coverphoto:hover{
    background-color: rgb(27, 27, 27);
}
.profile-text{
    margin-left: 25%;
    padding-top: 20%;
    font-size: 35px;
    color: wheat;
    font-weight: 500;
}
.cover-text{
    margin-left: 30%;
    padding-top: 5%;
    color: whitesmoke;
    font-weight: 500;
    font-size: 80px;

}
.add-party-details{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    border-radius: 5px;
    outline: 0;
    background-color: #1de8b4;
    border: 1px solid #1de8b4;
    /* margin-left: 80%; */
    margin-bottom: 2%;
    /* margin-top: -30px; */
    width: 50%;
    float: right;
    padding: 6px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
}
.hero1{
    position: relative;
    width:100%;
    height: 350px;
    /* border: 1px solid black; */
    background-color: rgba(102, 204, 153, 0.2);
}

.hero2 {
    width: 180px;
    height: 180px;
    border: 1px solid black;
    border-radius: 50%;
    position: relative;
    left: 50px;
    bottom: 100px;
    background-color: #fff;
    float: left;
    border: 3px solid #1de8b4;
    box-shadow: 0px 0px 10px 4px #1de8b4; 
}

.party-logo-edit-pen{
    padding-left: 20px;
    position: absolute;
    color: #1de8b4;
    cursor: pointer;
    width: 20px;
    padding-top: 10px;
    padding-right: 10px;
}
.hero2 img {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}
 .fa-icon-pen-profile{
    position: absolute;
    top: 80%;
    left: 88%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #707070;
    border-radius: 50%;
    color: #1de8b4;
    font-size: 14px;
    padding: 10px 10px;
    border:5px solid #ffffff;
    cursor: pointer;
    text-align: center;
}

.fa-icon-pen-profile:hover{
    opacity: 1;
}

.hero3 {
    /* border: 1px solid black; */
    margin-top: 10px;
    width: 80%;
    float: right;
    height: auto !important;
    padding: 5px;
}


.hero3-body-left{
    float: left;
    width: 77%;
    /* border: 1px solid black; */
}
.hero3-body-right{
    float: right;
    /* border: 1px solid black; */
    margin-right: 2%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.hero3-body-right p {
    font-size: 80%;
    
}

.hero3-body-right button {
    border: 2px solid #66CC99;
    background-color: #ffffff;
    color: #000000;
    font-size: 85%;
    font-weight: bold;
    padding: 5px;
    width: 120px;
    border-radius: 50px;
    vertical-align: middle;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.50);
}



.edit-content{
    margin-left: 80%;
}

.edit-inside-image{
    width: 15%;
}

.hero-footer-1{
    position: relative;
}
.logo-below-text{
    margin-left: 10%;
}

.hero-footer-2{
    /* border: 1px solid green; */
    float: left;
    /* margin-right: 5%;
    margin-top: 0; */
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: row;
    


}
.hero-footer-2 p {
    margin-top: 35%;
}


.user-name{
font-size: 25px;
font-weight: 700;
}
.part_details{
    margin-right: 0%;
    float: right;
    text-align: right;
}
.party-name{
    font-size: 12px;
    color: rgb(83, 83, 83);
    font-weight: 600;
    padding-left: 10px;
}
.bjp-logo{
    width: 10%;
    margin-left: 85%;
    margin-top: -5%;
}
.party-image {
    width: 100px;
    height: 100px;
    background-color: #ccc;
    border-radius: 50%;
    margin-left: 20%;
}
.text-button{
    margin-top: 1%;
    margin-left: 75%;
}
@media screen and (min-width: 1380px){
.hero-text-box, .hero-text-box-follower {
    /* position: absolute; */
    width: 90%;
    height: 60%;
    /* height: 500px; */
    margin: 6% 5% auto 5%;
    /* border: 1px solid black; */
    margin-bottom: 2%;
}
}
.folloers-count{
    font-size: 14px;
    padding-left: 10px;
    font-weight: 600;
    color: #707070;

}

.select-drop-down{
    position: absolute;
    float: right;
  top:60%;
  left: 90%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: #1de8b4;
  backdrop-filter: blur(1px);
  background-color: #fbfbfb;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 30px;
  text-align: center;
}
.btn-success:focus{
    background-color: #1de8b4!important;
}
.btn-success{
    background-color: #1de8b4!important;
    border:1px solid #1de8b4;
    padding: 2px 4px;
}
.leader-description{
    font-size: 13px;
    font-weight: bold;
}
.user-occupation, .party-text{
    font-size:12px;
    font-weight:500;
    color:#525252;
}
.user-emailid, .user-mobile{
    font-size:12px;
    font-weight:600;
}
@media screen and (min-width:1380px){
    .card-heading {
        color: #122A39;
        font-size: 14px;
        padding-left: 10px;
        font-weight: 600;
    }


    .hero-text-box {
        position: relative;
        width: 95%;
        height: 450px !important;
        margin: 0 10% 5% auto 5%;
        /* border: 1px solid black; */
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        margin-bottom: 30px;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 6%;
}
.hero-text-box-follower {
    position: relative;
    width: 100%;
    height: 465px !important;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.user-name {
    font-size: 25px;
    font-weight: 700;
}
.user-occupation, .party-text {
    font-size: 12x;
    font-weight: 500;
    color: #525252;
}
.user-emailid, .user-mobile{
    font-size:12px;
    font-weight:600;
}
.hero1 {
    position: relative;
    width: 100%;
    height: 331px;
    /* border: 1px solid black; */
    background-color: rgba(102, 204, 153, 0.2);
}
.hero2 img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}
.hero2 {
    width: 150px;
    height: 150px;
    border: 1px solid black;
    border-radius: 50%;
    position: relative;
    left: 50px;
    bottom: 100px;
    background-color: #fff;
    float: left;
    border: 3px solid #1de8b4;
    box-shadow: 0px 0px 10px 4px #1de8b4;
}
.coverphoto, .coverpicadjuster, .picsubmit {
    
    top: 60%;
    left: 95%;
    
}
.left-profile {
    max-width: 35%;
    padding-left: 5%;
}
}

@media screen and (min-width: 1439px){
.left-profile {
    max-width: 35%;
    padding-left: 5%;
}
}



.button-edit-profile{
    border-radius: 5px;
    outline: 0;
    background-color: #1de8b4;
    border: 1px solid #1de8b4;
    /* margin-right: 1%; */
    margin-left: 35%;
    /* margin-top: 7px; */
    padding: 4px 6px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;

}
.form-edit-education{
    width: 100% ;
    margin-left: 0%;
    padding: 15px;
    box-shadow: 10px 0 45px 0px rgba(0, 0, 0, 0.2), 0 5px 5px 7px rgba(0, 0, 0, 0.2);   
    background-color: #ffffff;
}
.edit-details-label{
    color: rgb(93, 93, 93);
    font-size: 18px;
    font-weight: 800;
}
.experiance-label{
    color: #4d4d4d;
    font-size: 14px;
    margin-top: 10px;
  }
  .required-start{
    color: #1de8b4;
}
.rowspacing{
    margin-top: 20px;
}
.occupationdetails{
    font-size :12px;
    font-weight:500;
    color : #525252;
}
@media screen and (max-width: 920px){


   

    .party_details_target{
      
        right: 8%;
        top: -64px;
    }
    .target_leader_cover_pic {
        position: relative;
        width: 100%;
        height: 190px;
        border: 1px solid black;
        background-color: rgba(102, 204, 153, 0.2);
    }
    .occupationdetails {
        margin-bottom: 0px !important;
        font-size :12px;
    font-weight:500;
    color : #525252;
    }
    .emailprofile{
        margin-bottom: 0px !important;
    }


    .leader-feed-alignment-div {
        margin-left: 0%;
        margin-right: 0%;
    }
    .hero-text-box {
        position: relative;
        width: 95%;
        height: auto !important;
        margin: 0 10% 5% auto 5%;
        /* border: 1px solid black; */
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        margin-bottom: 30px;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 6%;
    }




    .hero-text-box-follower {
        position: relative;
        width: 95%;
        height: 330px !important;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 18%;
        margin-bottom: 5%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
    }
    .hero1 {
        position: relative;
        width: 100%;
        height: 190px;
        /* border: 1px solid black; */
        background-color: rgba(102, 204, 153, 0.2);
    }
    .hero2 img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;
    }
    .hero2 {
        width: 100px;
        height: 100px;
        border: 1px solid black;
        border-radius: 50%;
        position: relative;
        left: 20px;
        bottom: 45px;
        background-color: #fff;
        float: left;
        border: 3px solid #1de8b4;
        box-shadow: 0px 0px 10px 4px #1de8b4;
    }
    .user-name {
        font-size: 16px;
        font-weight: bold;
    }
    .hero3 {
        /* border: 1px solid black; */
        position: absolute;
        left: 22%;
        top: 64%;
        width: 80%;
        float: right;
        height: auto !important;
        padding: 25px;
    }
    .button-edit-profile{
margin-left: 1%;
    }
    .user-occupation, .party-text {
        font-size: 12px;
        font-weight: 500;
        color: #525252;
        margin-right: 10px;
    }
    .left-profile {
        max-width: 100%;
        padding-left: 5%;
        flex-basis: unset;
    }
    .popup-content {
        width: 76% !important;
    }
    .experiance-label {
        color: #4d4d4d;
        font-size: 11px;
        margin-top: 0px;
    }
    .rowspacing {
        margin-top: 10px;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 26px;
        margin-bottom: -12px;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 1px;
        bottom: 0px;
        background-color: white;
        transition: .4s;
    }

}
.heading{
  color: rgb(77, 77, 77);
  font-size: 11px;
  font-weight: 600;
  padding-bottom: 10px;
  max-width: 48%;
}
label {
  
  margin-bottom: 0.5px !important;
}
.about-row{
  width: 100%;
}
.display-content-about{
  color: rgb(104, 105, 105);
  font-size: 11px;
  font-weight: 600;
float: left;
width: 50%;
word-wrap: break-word;
}
.display-content{
  color: rgb(104, 105, 105);
font-size: 11px;
font-weight: 600    ;
}
.display-content-about1{
  color: rgb(104, 105, 105);
  font-size: 11px;
  word-wrap: break-word;
  }
.fa-icon-pencil{
  padding: 5px;
  padding-right: 10px;
  cursor: pointer;
  float: right;
  color: #1de8b4;
  font-size: 15px;
}
.card-heading{
float: center;
}
.InputGroup{
  outline:none!important;
  border: 1px solid #1de8b4!important;
  box-shadow: 0px 0px 2px #1de8b4!important;
}
a img, a:hover img{
  border: none !important;
  background: transparent !important
  }
  
  .entry img{
  border: none !important;
  background: transparent !important
  }
  #react-confirm-alert{
    z-index: 10!important;
  }
  .time-ago-date{
    font-size: 12px;
    color: #4D4D4D;
}
input:focus, textarea:focus, select:focus{
  outline:none;
  border: 1px solid #1de8b4;
  box-shadow: 0px 0px 2px #1de8b4;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

button:focus {outline:0;}
.react-datepicker-wrapper {
  display: block!important;
  padding: 0;
  border: 0;
}
a:hover{
  color: #1de8b4;
  text-decoration: none;
}
a:active{
  text-decoration: none;
}
a:visited{
  text-decoration: none;
}
::placeholder{
  color: #bbb9b9!important;
  font-size: 12px!important;
  font-weight: 500!important;
  font-family:-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue","Fira Sans",Ubuntu,Oxygen,"Oxygen Sans",Cantarell,"Droid Sans","Apple Color Emoji","Segoe UI Emoji","Segoe UI Emoji","Segoe UI Symbol","Lucida Grande",Helvetica,Arial,sans-serif !important;
}
.foo-bar{
  border-top-left-radius:10px  !important;
  border-bottom-right-radius: 10px !important;
  font-size: 14px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
 background-color: rgb(95, 246, 201) !important;
 color: rgb(75, 72, 72);
}
.foo-bar-error{
  border-top-left-radius:10px  !important;
  border-bottom-right-radius: 10px !important;
  font-size: 14px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
 background-color: salmon !important;
 color: rgb(255, 255, 255);
}
.popup-overlay {
  backdrop-filter: blur(10px) opacity() !important;
  background: rgba(63, 63, 63, 0) !important;
}
.input{
  font-family: sans-serif!important;
  font-weight: 500!important;
}
#error{
  border: 1px solid red;
  box-shadow: 0px 0px 5px 0px red;
}
.close-popup {
  float: right;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000!important;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  margin-right: 2%!important;
  cursor: pointer;
  margin-top: 10px;
}
body{
  font-family: loto!important;
}
.close-popup-follower{
  position: absolute;
    /* float: right; */
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000!important;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    /* margin-right: 2%!important; */
    cursor: pointer;
    margin-left: 68%;
    margin-top: 10px;
}